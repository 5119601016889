
import VueCropper, { VueCropperMethods } from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import router from '@/router';
import { useZonesStore } from '@/store/ZonesStore';
import { defineComponent } from 'vue';
import 'vue-router/dist/vue-router';
import BandeauBreadcrumb from '@/components/BandeauBreadcrumb.vue';
import { useBreadcrumbStore } from '@/store/BreadcrumbStore';
import { Zone } from '@/interface/Zone';
import { useApiStore } from '@/store/ApiStore';

interface ImgStyle {
    width: string;
    height: string;
}

export default defineComponent({
    setup() {
        const zonesStore = useZonesStore();
        const breadcrumbStore = useBreadcrumbStore();
        const apiStore = useApiStore();

        return { zonesStore, breadcrumbStore, apiStore };
    },
    components: { BandeauBreadcrumb, VueCropper },
    data() {
        return new (class {
            zone: Zone | null = null;
            mime_type: string = '';
            selectedFile: string = '';
            dialog: boolean = false;
            rotateImage: number = 0;
            imgStyle: ImgStyle = {
                width: '800px',
                height: '800px'
            };
        })();
    },
    watch: {
        rotateImage(newValue) {
            (this.$refs.cropper as any).rotateTo(newValue);
        }
    },
    methods: {
        saveImage() {
            this.dialog = false;
            let route = '/newPage/';
            if (this.zone !== null) {
                (this.$refs.cropper as any).getCroppedCanvas().toBlob((blob: any) => {
                    if (this.zone !== null) {
                        this.apiStore.saveImage(blob, this.zone).then(() => {
                            if (this.zone !== null) {
                                router.push(route + this.zone.id);
                            }
                        });
                    }
                }, 'image/jpeg');
            }
        },
        scalePreserveAspectRatio(imgW: number, imgH: number, maxW: number, maxH: number) {
            return Math.min(maxW / imgW, maxH / imgH);
        },
        getImageDimensions(file: string) {
            return new Promise(function (resolved) {
                let i = new Image();
                i.onload = function () {
                    resolved({ width: i.width, height: i.height });
                };
                i.src = file;
            });
        },
        onFileSelect(e: any) {
            const file = e.target.files[0];
            this.mime_type = file.type;
            if (typeof FileReader === 'function') {
                this.dialog = true;
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.getImageDimensions(event.target.result).then((res: any) => {
                        let sizer = this.scalePreserveAspectRatio(res.width, res.height, 800, 800);
                        this.imgStyle.width = res.width * sizer + 'px';
                        this.imgStyle.height = res.height * sizer + 'px';
                    });
                    this.selectedFile = event.target.result;
                    (this.$refs.cropper as VueCropperMethods).replace(this.selectedFile);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        }
    },
    mounted() {
        this.zone = this.zonesStore.getZoneById(this.$route.params.id.toString());
        this.breadcrumbStore.updateItems(this.$route.params.id as string);
        this.breadcrumbStore.updateDisabledLink(parseInt(this.$route.params.id as string));
    },
    name: 'upload_component'
});
