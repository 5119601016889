import { defineStore } from 'pinia';
import { Zone } from '@/interface/Zone';
import { Anchor } from '@/interface/Anchor';
import { Expertise } from '@/interface/Expertise';
import ZoneClass from '@/class/ZoneClass';
import { useApiStore } from '@/store/ApiStore';
import { StageSize } from '@/interface/StageSize';
import AnchorClass from '@/class/AnchorClass';
import { ZoneApi } from '@/interface/ZoneApi';
import ZoneApiClass from '@/class/ZoneApiClass';
import Konva from 'konva';

interface ZoneState {
    zones: Zone[];
    canEdit: boolean;
    selectedShapeId: string | null;
    expertiseData: Expertise | null;
    hasSaveZone: Zone | null;
    point0: [number, number];
}

export const useZonesStore = defineStore({
    id: 'zones',
    state: (): ZoneState => {
        return {
            zones: [],
            canEdit: true,
            selectedShapeId: null,
            expertiseData: null,
            hasSaveZone: null,
            point0: [0, 0]
        };
    },
    persist: {
        storage: sessionStorage
    },
    getters: {
        getAnchors(state): Anchor[] {
            if (state.selectedShapeId !== null) {
                return this.getZoneById(state.selectedShapeId)?.anchors ?? [];
            }
            return [];
        },
        getZonesByParentId: (state) => {
            return (id: string) =>
                state.zones.filter((zone) => {
                    return zone.id !== id && zone.parent_id === id;
                });
        },
        getZoneById: (state) => {
            return (id: string | null) => state.zones.find((zone) => zone.id === id) ?? null;
        },
        getImgBreadcrumbParent: (state) => {
            return (parent_id: string): string => {
                const zoneParent = state.zones.find((zone) => zone.id === parent_id) ?? null;
                if (zoneParent !== null) {
                    return zoneParent.imgBreadcrumb ?? '';
                }
                return '';
            };
        },
        hasChildrensZone: (state) => {
            return (id: string | null): boolean => {
                return state.zones.filter((zone) => zone.parent_id === id).length > 0;
            };
        }
    },
    actions: {
        setHaveSaveZone(value: Zone | null): void {
            this.hasSaveZone = value;
        },
        setExpertiseData(value: Expertise): void {
            this.expertiseData = value;
        },
        setCanEdit(value: boolean): void {
            const apiStore = useApiStore();
            if (apiStore.isReadonly) {
                this.canEdit = false;
            } else {
                this.canEdit = value;
            }
        },
        setSelectedShapeId(value: string | null): void {
            this.selectedShapeId = value;
        },
        addNewZone(zone: Zone) {
            this.zones.push(zone);
        },
        async createNewZone(zoneApi: ZoneApi) {
            if (!zoneApi) return;
            const apiStore = useApiStore();
            return apiStore.createApiZone(zoneApi).then((res: any) => {
                const id = res.data.id.toString();
                let needUpdate = false;
                if (zoneApi.parentId === 0) {
                    zoneApi.parentId = parseInt(id);
                    needUpdate = true;
                }
                if (zoneApi.firstPageId === 0) {
                    zoneApi.firstPageId = parseInt(id);
                    needUpdate = true;
                }

                if (needUpdate) {
                    return apiStore.updateApiZone(id, zoneApi).then(() => {
                        const zone = new ZoneClass(id, apiStore.expertiseId);
                        zone.parent_id = id;
                        zone.first_page_id = id;
                        // this.setCanEdit(false);
                        this.addNewZone(zone);
                        return id;
                    });
                }

                const zone = new ZoneClass(id, apiStore.expertiseId);
                zone.parent_id = zoneApi.parentId.toString();
                zone.first_page_id = zoneApi.firstPageId.toString();
                this.addNewZone(zone);

                return id;
            });
        },
        async setZones(rapport: Expertise) {
            const apiStore = useApiStore();
            for (const zoneApi of rapport.zones) {
                const id = zoneApi.id;
                const zone = new ZoneClass(id.toString(), apiStore.expertiseId);
                zone.parent_id = zoneApi.parentId.toString();
                zone.first_page_id = zoneApi.firstPageId.toString();
                zone.pageType = zoneApi.type;
                zone.imgMainApi = zoneApi.imgMain !== null ? 'api/media/' + zoneApi.imgMain.id : null;
                zone.imgBreadcrumbApi = zoneApi.imgBreadcrumb !== null ? 'api/media/' + zoneApi.imgBreadcrumb.id : null;
                zone.points = zoneApi.points;
                zone.Expertise = zoneApi.Expertise;
                zone.nomApi = zoneApi.nom;
                zone.comments = zoneApi.comments;
                zone.stroke = zoneApi.stroke;
                zone.closed = true;
                if (zoneApi.imgMain !== null) {
                    zone.imgMain = zoneApi.imgMain.contentUrl;
                }
                if (zoneApi.imgBreadcrumb !== null) {
                    zone.imgBreadcrumb = zoneApi.imgBreadcrumb.contentUrl;
                }
                this.addNewZone(zone);
            }
        },
        removeZone(id: string, isParent: boolean): void {
            const index = this.zones.findIndex((zone) => zone.id === id) ?? null;
            const indexChildrens: { zone_id: string; indexNumber: number }[] = [];
            const apiStore = useApiStore();

            if (isParent) {
                this.zones.forEach((zone: Zone, index: number) => {
                    if (zone.parent_id === id) {
                        indexChildrens.push({ zone_id: zone.id, indexNumber: index });
                    }
                });
            }

            if (indexChildrens.length > 0) {
                indexChildrens.forEach((obj: { zone_id: string; indexNumber: number }) => {
                    apiStore.deleteApiZone(obj.zone_id).then(() => {
                        this.zones.splice(obj.indexNumber, 1);
                    });
                });
            }
            if (index !== null) {
                apiStore.deleteApiZone(id).then(() => {
                    this.zones.splice(index, 1);
                });
            }
        },
        getResponsivePoints(stageSize: StageSize, zone: Zone): number[] {
            const responsivePoints: number[] = [];
            zone.points.forEach((point: number, index: number) => {
                if (index % 2 === 0) {
                    // X
                    responsivePoints.push(point * stageSize.width);
                } else {
                    // Y
                    responsivePoints.push(point * stageSize.height);
                }
            });
            return responsivePoints;
        },
        setAnchors(stageSize: StageSize, zone: Zone): void {
            zone.anchors = [];
            const chunksize = 2;
            const chunks: any[] = [];

            zone.points.forEach((item: number): void => {
                if (!chunks.length || chunks[chunks.length - 1].length == chunksize) {
                    chunks.push([]);
                }

                chunks[chunks.length - 1].push(item);
            });

            chunks.forEach((point: any, index: number): void => {
                const anchor = new AnchorClass('anchor_' + index.toString(), point[0] * stageSize.width, point[1] * stageSize.height);
                if (index.toString() === '0') {
                    this.setPoint0([point[0] * stageSize.width, point[1] * stageSize.height]);
                }
                zone.anchors.push(anchor);
            });
        },
        setPoint0(data: [number, number]) {
            if (this.point0[0] === 0 && this.point0[1] === 0) {
                this.point0 = data;
            }
        },
        resetPoint0() {
            this.point0 = [0, 0];
        },
        addPoint(coord: { x: number; y: number }, stageSize: StageSize, zone: Zone) {
            zone.tempPoints.push(coord.x / stageSize.width);
            zone.tempPoints.push(coord.y / stageSize.height);
            zone.points = zone.tempPoints;
        },
        addCurrentMousePoint(coord: { x: number; y: number }, stageSize: StageSize, zone: Zone) {
            zone.points = zone.tempPoints;
            zone.points = zone.points.concat(zone.closed ? [] : [coord.x / stageSize.width, coord.y / stageSize.height]);
        },
        enableDraggableAnchors(zone: Zone): void {
            zone.anchors.forEach((anchor: Anchor): void => {
                anchor.draggable = true;
            });
        },
        addComment(zone: Zone, commentId: number, commentData: string, commentType: string) {
            zone.comments.push({ id: commentId, content: commentData, type: commentType });
        },
        editComment(zone: Zone, commentId: number, commentData: string, commentType: string) {
            zone.comments.map((comment) => {
                if (comment.id === commentId && comment.type === commentType) {
                    comment.content = commentData;
                }
            });
        }
        // generateImgBreadcrumb(zone: Zone) {
        //     const stage = this.stage;
        //     const apiStore = useApiStore();
        //     if (stage !== null) {
        //         return new Promise((resolve) => {
        //             if (zone.parent_id === zone.first_page_id) {
        //                 zone.fill = '#D0D0D0';
        //                 zone.opacity = 0.7;
        //                 setTimeout(() => {
        //                     const stage64 = stage.toDataURL({
        //                         pixelRatio: 0.5
        //                     });
        //                     fetch(stage64)
        //                         .then((res) => res.blob())
        //                         .then((blob: Blob) => {
        //                             apiStore.saveImageBreadcrumb(blob, zone).then(() => {
        //                                 zone.fill = null;
        //                                 zone.opacity = null;
        //                                 resolve(true);
        //                             });
        //                         });
        //                 }, 1);
        //             } else {
        //                 zone.imgBreadcrumb = this.getImgBreadcrumbParent(zone.parent_id);
        //                 resolve(true);
        //             }
        //         });
        //     }
        // }
    }
});
