import { defineStore } from 'pinia';
import axios from 'axios';
import { ZoneApi } from '@/interface/ZoneApi';
import { Zone } from '@/interface/Zone';
import ZoneApiClass from '@/class/ZoneApiClass';
import { useZonesStore } from '@/store/ZonesStore';
import CommentClass from '@/class/CommentClass';

interface ApiState {
    expertiseId: string;
    apiToken: string | null;
    isReadonly: boolean;
}

export const useApiStore = defineStore({
    id: 'api',
    state: (): ApiState => {
        return {
            expertiseId: '0',
            apiToken: null,
            isReadonly: false
        };
    },
    persist: {
        storage: sessionStorage
    },
    actions: {
        setIsReadonly(value: boolean): void {
            this.isReadonly = value;
        },
        setExpertiseId(value: string): void {
            this.expertiseId = value;
        },
        setApiToken(value: string): void {
            this.apiToken = value;
        },
        getExpertiseData() {
            return axios.get(process.env.VUE_APP_URL_API_EXPERTISE + this.expertiseId, { params: { 'api-token': this.apiToken } });
        },
        getImageApi(zone: Zone, idImgMain: string) {
            return axios.get(process.env.VUE_APP_URL_API_MEDIA + '/' + idImgMain);
        },
        createApiZone(zoneApi: ZoneApi) {
            return axios.post(process.env.VUE_APP_URL_API_ZONE, zoneApi, {
                params: { 'api-token': this.apiToken }
            });
        },
        updateApiZone(id: string, zoneApi: ZoneApi) {
            return axios.put(process.env.VUE_APP_URL_API_ZONE + '/' + id, zoneApi, {
                params: { 'api-token': this.apiToken }
            });
        },
        deleteApiZone(id: string) {
            return axios.delete(process.env.VUE_APP_URL_API_ZONE + '/' + id, {
                params: { 'api-token': this.apiToken }
            });
        },
        saveApiImage(formData: FormData) {
            return axios.post(process.env.VUE_APP_URL_API_MEDIA, formData, { params: { 'api-token': this.apiToken } });
        },
        saveImage(blob: Blob, zone: Zone) {
            const formData = new FormData();
            formData.append('file', blob, 'name.jpeg');

            return this.saveApiImage(formData).then((res) => {
                if (res.status === 201 && zone !== null) {
                    zone.imgMainApi = 'api/media/' + res.data.id;
                    zone.timestampApi = Date.now().toString();
                    const zoneApi = new ZoneApiClass(this.expertiseId, zone);
                    return this.updateApiZone(zone.id, zoneApi).then((resp) => {
                        zone.imgMain = resp.data.imgMain.contentUrl;
                    });
                }
            });
        },
        saveImageBreadcrumb(blob: any, zone: Zone) {
            const formData = new FormData();
            formData.append('file', blob, 'name.jpeg');
            const oldImgBreadCrumb = zone.imgBreadcrumbApi;
            if (!this.isReadonly) {
                return this.saveApiImage(formData).then((res) => {
                    if (res.status === 201 && zone !== null) {
                        zone.imgBreadcrumbApi = 'api/media/' + res.data.id;
                        zone.timestampApi = Date.now().toString();
                        const zoneApi = new ZoneApiClass(this.expertiseId, zone);
                        if (oldImgBreadCrumb !== null) {
                            return this.deleteImageBreadcrumb(oldImgBreadCrumb).then(() => {
                                return this.updateApiZone(zone.id, zoneApi).then((res) => {
                                    if (res.data.imgBreadcrumb !== null) {
                                        zone.imgBreadcrumb = res.data.imgBreadcrumb.contentUrl;
                                    }
                                });
                            });
                        } else {
                            return this.updateApiZone(zone.id, zoneApi).then((res) => {
                                if (res.data.imgBreadcrumb !== null) {
                                    zone.imgBreadcrumb = res.data.imgBreadcrumb.contentUrl;
                                }
                            });
                        }
                    }
                });
            } else {
                return new Promise((resolve) => {
                    resolve(true);
                });
            }
        },
        deleteImageBreadcrumb(imgBreadcrumb: string) {
            const id = imgBreadcrumb.split('/').pop();
            return axios.delete(process.env.VUE_APP_URL_API_MEDIA + '/' + id, { params: { 'api-token': this.apiToken } });
        },
        getCommentApi(commentId: number) {
            return axios.get(process.env.VUE_APP_URL_API_COMMENT + '/' + commentId, {
                params: { 'api-token': this.apiToken }
            });
        },
        addCommentApi(comment: CommentClass) {
            return axios.post(process.env.VUE_APP_URL_API_COMMENT, comment, {
                params: { 'api-token': this.apiToken }
            });
        },
        updateCommentApi(comment: CommentClass, commentId: number) {
            return axios.put(process.env.VUE_APP_URL_API_COMMENT + '/' + commentId, comment, {
                params: { 'api-token': this.apiToken }
            });
        }
    }
});
