import { createRouter, createWebHistory } from 'vue-router';
import Upload from '@/components/Upload.vue';
import CreatePage from '@/components/CreatePage.vue';
import AuthComponent from '@/components/Authenticate.vue';
import NotFound from '@/components/NotFound.vue';

const routes = [
    { path: '/authenticate', name: 'Authenticate', component: AuthComponent },
    { path: '/authenticate/readonly', name: 'AuthenticateReadonly', component: AuthComponent },
    { path: '/:id(\\d+)', name: 'Upload', component: Upload },
    {
        path: '/newPage/:id(\\d+)',
        name: 'NewPage',
        component: CreatePage
    },
    {
        // catch all 404 - define at the very end
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: NotFound
    }
];
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes // short for `routes: routes`
});

export default router;
