import { BreadcrumbItem } from '@/interface/BreadcrumbItem';

export default class BreadcrumbClass implements BreadcrumbItem {
    id: number;
    title: string;
    disabled: boolean = true;
    to: string | null;
    href: string | undefined;

    constructor(id: number, title: string, to: string | null, href: string | null = null) {
        this.id = id;
        this.title = title;
        this.to = to;
        if (href !== null) {
            this.href = href;
        }
    }
}
