import '@fortawesome/fontawesome-free/css/all.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import VueKonva from 'vue-konva';
import Routes from './router';
// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import axios from 'axios';
import { aliases, fa } from 'vuetify/iconsets/fa';

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response !== undefined && error.response.data !== undefined && error.response.data['hydra:description'] === 'Api-token not recognized') {
            window.location.href = process.env.VUE_APP_URL_REDIRECT;
        }
    }
);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#185686',
        secondary: '#2f3d4a',
        error: '#B00020',
        info: '#185686',
        success: '#20c997',
        warning: '#e79d19'
    }
};

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme
        }
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa
        }
    }
});

createApp(App).use(vuetify).use(pinia).use(VueKonva).use(Routes).mount('#app');
