import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VSwatches = _resolveComponent("VSwatches")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_col, {
    cols: "3",
    class: "ma-auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              (this.getZoneById(this.$route.params.id) !== null ? this.getZoneById(this.$route.params.id).pageType === 'page' : false)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                    default: _withCtx(() => [
                      (this.selectedShapeId === null && this.canEdit)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "12",
                            class: "d-flex justify-center"
                          }, {
                            default: _withCtx(() => [
                              (!this.loading)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    color: "warning",
                                    "append-icon": "fa-plus",
                                    onClick: _ctx.addZone
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Ajouter une zone ")
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                              (!this.loading)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 1,
                                    color: "secondary",
                                    onClick: _ctx.updateImg,
                                    class: "ml-2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Modifier Photo ")
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                              (this.loading)
                                ? (_openBlock(), _createBlock(_component_v_col, {
                                    key: 2,
                                    cols: "8"
                                  }, {
                                    default: _withCtx(() => [
                                      (this.loading)
                                        ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                            key: 0,
                                            color: "blue-lighten-3",
                                            indeterminate: "",
                                            class: "mx-auto",
                                            rounded: ""
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (this.selectedShapeId !== null && this.canEdit)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 1,
                            cols: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { dense: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "5",
                                    class: "my-auto"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Titre de la zone",
                                        modelValue: this.titleZone,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.titleZone) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "2",
                                    class: "my-auto d-flex justify-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_VSwatches, {
                                        modelValue: this.color,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.color) = $event)),
                                        "popover-x": "left",
                                        swatches: "text-advanced"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "5",
                                    class: "my-auto"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_radio_group, {
                                        inline: "",
                                        modelValue: this.zoneType,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.zoneType) = $event)),
                                        class: "d-flex justify-center"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_radio, {
                                            label: "Page",
                                            value: "page",
                                            "aria-checked": "true"
                                          }),
                                          _createVNode(_component_v_radio, {
                                            label: "HD",
                                            value: "zoom"
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { dense: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "my-auto d-flex justify-center" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, {
                                            cols: "auto",
                                            class: "d-flex justify-center"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                color: "warning",
                                                onClick: _ctx.saveZone,
                                                density: "comfortable",
                                                rounded: "xs"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(" Valider ")
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "auto",
                                            class: "d-flex justify-center"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                color: "secondary",
                                                onClick: _ctx.cancel,
                                                density: "comfortable",
                                                rounded: "xs"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(" Retour ")
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "auto",
                                            class: "d-flex justify-center"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                class: "btn-secondary",
                                                onClick: _ctx.canDeleteZone,
                                                density: "comfortable",
                                                rounded: "xs"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(" Supprimer ")
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}