
import { defineComponent } from 'vue';
import { mapState, mapStores } from 'pinia';
import { useZonesStore } from '@/store/ZonesStore';
import { Zone } from '@/interface/Zone';
import { useApiStore } from '@/store/ApiStore';
import ZoneApiClass from '@/class/ZoneApiClass';
import router from '@/router';
import { VSwatches } from 'vue3-swatches';
import 'vue3-swatches/dist/style.css';

export default defineComponent({
    name: 'ActionsHeader',
    components: { VSwatches },
    data() {
        return new (class {
            zoneType: string = 'page';
            loading: boolean = false;
            titleZone: string = 'Page';
            color: string = '#0000ff';
        })();
    },
    watch: {
        color(newValue) {
            const selectedRect = this.getZoneById(this.selectedShapeId);
            if (selectedRect !== null) {
                selectedRect.stroke = newValue;
            }
        },
        zoneType(newValue) {
            const selectedRect = this.getZoneById(this.selectedShapeId);
            if (selectedRect !== null) {
                selectedRect.pageType = newValue;
            }
        },
        selectedShapeId(newValue, oldValue) {
            if (newValue !== null) {
                const selectedRect = this.getZoneById(newValue);
                if (selectedRect !== null) {
                    this.titleZone = selectedRect.nomApi ?? 'Page';
                    this.color = selectedRect.stroke ?? '#0000ff';
                    this.zoneType = selectedRect.pageType;
                }
            } else {
                const selectedOldRect = this.getZoneById(oldValue);
                if (selectedOldRect !== null && !selectedOldRect.closed) {
                    selectedOldRect.closed = true;
                }
            }
        }
    },
    computed: {
        ...mapStores(useZonesStore, useApiStore),
        ...mapState(useZonesStore, ['zones', 'canEdit', 'selectedShapeId', 'getZoneById', 'hasChildrensZone']),
        ...mapState(useApiStore, ['expertiseId'])
    },
    methods: {
        saveZone() {
            let currentRectangle = this.getZoneById(this.selectedShapeId);
            if (currentRectangle !== null) {
                if (currentRectangle.points.length >= 6) {
                    currentRectangle.pageType = this.zoneType;
                    currentRectangle.nomApi = this.titleZone;
                    const apiZone = new ZoneApiClass(this.expertiseId, currentRectangle);
                    this.apiStore.updateApiZone(currentRectangle.id, apiZone).then(() => {
                        if (currentRectangle !== null && currentRectangle.imgMain === null) {
                            this.zonesStore.setHaveSaveZone(currentRectangle);
                            this.titleZone = 'Page';
                            this.zonesStore.setSelectedShapeId(null);
                            // this.zonesStore.setCanEdit(false);
                            // router.push('/' + currentRectangle.id);
                        }
                    });
                } else {
                    this.deleteZone(false, this.selectedShapeId);
                }
            }
            this.titleZone = 'Page';
            this.zonesStore.setSelectedShapeId(null);
        },
        addZone() {
            this.loading = true;
            let newZone = new ZoneApiClass(this.expertiseId);
            let page = this.getZoneById(this.$route.params.id as string) as Zone | null;
            if (page !== null) {
                newZone.parentId = parseInt(page.id);
                newZone.firstPageId = parseInt(page.first_page_id);
                this.zonesStore.createNewZone(newZone).then((id) => {
                    this.zonesStore.setSelectedShapeId(id);
                    this.loading = false;
                });
            }
        },
        canDeleteZone() {
            const hasChildrensZone = this.hasChildrensZone(this.selectedShapeId);
            if (hasChildrensZone) {
                if (confirm('Cette zone comporte des sous zones, êtes vous sûr de vouloir la supprimer ?')) {
                    this.deleteZone(true);
                }
            } else {
                this.deleteZone();
            }
        },
        deleteZone(isParent: boolean = false, selectedShapeId: string | null = null) {
            this.zonesStore.resetPoint0();
            let currentRectangle = this.getZoneById(selectedShapeId ?? this.selectedShapeId);
            if (currentRectangle !== null) {
                this.zonesStore.setSelectedShapeId(null);
                this.zonesStore.removeZone(currentRectangle.id, isParent);
            }
        },
        cancel() {
            this.zonesStore.setSelectedShapeId(null);
        },
        updateImg() {
            this.zonesStore.setSelectedShapeId(null);
            // this.zonesStore.setCanEdit(false);
            router.push('/' + this.$route.params.id);
        }
    }
});
