import { Zone } from '@/interface/Zone';
import { Anchor } from '@/interface/Anchor';

export default class ZoneClass implements Zone {
    base64: string | null;
    imgMain: string | null;
    imgMainApi: string | null = null;
    draggable: boolean = false;
    first_page_id: string = '0';
    id: string;
    nomApi: string | null = null;
    name: string = 'rect_';
    parent_id: string = '0';
    scaleX: number = 1;
    scaleY: number = 1;
    stroke: string = '#0000ff';
    fill: string | null = null;
    opacity: number | null = null;
    strokeWidth: number = 2;
    title: string | null;
    pageType: string = 'page';
    imgBreadcrumb: string | null = null;
    imgBreadcrumbApi: string | null = null;
    closed: boolean = false;
    points: number[] = [];
    currentMousePoint: number[] = [];
    tempPoints: number[] = [];
    anchors: Anchor[] = [];
    Expertise: string = '';
    comments: { id: number; content: string; type: string }[] = [];
    timestampApi: string = '1';

    constructor(id: string, expertiseId: string) {
        this.id = id;
        this.name += id;
        this.base64 = null;
        this.imgMain = null;
        this.title = null;
        this.Expertise = '/api/expertises/' + expertiseId;
    }
}
