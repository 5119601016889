import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-109aa5ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_informations_header = _resolveComponent("informations-header")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_col, {
      cols: "12",
      class: "bg-primary"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { align: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "2" }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: this.urlListeExpertise
                }, [
                  _createVNode(_component_v_img, {
                    src: "/favicon.ico",
                    width: "65",
                    height: "65"
                  })
                ], 8, _hoisted_1)
              ]),
              _: 1
            }),
            _createVNode(_component_informations_header),
            (this.getZoneById(_ctx.$route.params.id) !== null && this.getZoneById(_ctx.$route.params.id).pageType === 'page')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "2",
                  class: "my-auto"
                }, {
                  default: _withCtx(() => [
                    (this.$route.name === 'NewPage' && !this.isReadonly)
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 0,
                          class: "d-flex justify-center",
                          modelValue: this.btnSwitch,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.btnSwitch) = $event)),
                          density: "compact",
                          label: _ctx.labelActionPanel,
                          "hide-details": ""
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_col, { cols: "12" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "2" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: '/newPage/' + this.zones[0].first_page_id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      width: 150,
                      "aspect-ratio": "16/9",
                      cover: "",
                      src: _ctx.imgBreadcrumb
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "8",
              class: "my-auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_breadcrumbs, {
                  divider: "-",
                  class: "d-flex justify-center",
                  items: this.items,
                  replace: "",
                  exact: ""
                }, null, 8, ["items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}