
import { defineComponent } from 'vue';
import { mapState, mapStores } from 'pinia';
import { useBreadcrumbStore } from '@/store/BreadcrumbStore';
import { useZonesStore } from '@/store/ZonesStore';
import { useApiStore } from '@/store/ApiStore';
import InformationsHeader from '@/components/InformationsHeader.vue';

export default defineComponent({
    name: 'BandeauBreadcrumb',
    components: { InformationsHeader },
    data() {
        return new (class {
            btnSwitch: boolean = false;
            urlListeExpertise: string = process.env.VUE_APP_URL_REDIRECT;
        })();
    },
    watch: {
        btnSwitch(newValue) {
            this.zonesStore.setCanEdit(newValue);
            if (this.selectedShapeId !== null) {
                this.zonesStore.setCanEdit(true);
                this.btnSwitch = true;
            }
        }
        // canEdit(value) {
        //     this.btnSwitch = value;
        // }
    },
    computed: {
        ...mapStores(useBreadcrumbStore, useZonesStore),
        ...mapState(useApiStore, ['isReadonly']),
        ...mapState(useBreadcrumbStore, ['items']),
        ...mapState(useZonesStore, ['selectedShapeId']),
        ...mapState(useZonesStore, ['canEdit', 'getZonesByParentId', 'getZoneById', 'zones']),
        imgBreadcrumb(): string {
            const zone = this.getZoneById(this.$route.params.id as string);
            if (zone !== null) {
                return process.env.VUE_APP_URL_BACK_OFFICE + (zone.imgBreadcrumb ?? '');
            }
            return '';
        },
        labelActionPanel() {
            const zone = this.getZoneById(this.$route.params.id as string);
            //@ts-ignore
            if (this.canEdit && zone !== null && zone.pageType === 'page') {
                return 'Éditer';
            } else {
                this.zonesStore.setCanEdit(false);
                return 'Naviguer';
            }
        }
    },
    mounted() {
        this.btnSwitch = this.canEdit;
    }
});
