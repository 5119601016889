import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-center position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_col, { cols: 8 }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.info,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.info) = $event)),
          width: "1024"
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps({ "prepend-icon": "fa-regular fa-circle-question" }, props), {
              default: _withCtx(() => [
                _createTextVNode("Information Expertise")
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "d-flex justify-space-between" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Informations "),
                    _createVNode(_component_v_icon, {
                      icon: "fa-solid fa-circle-xmark",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.info = false))
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { style: {"margin":"0"} }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataItems, (items, index) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            cols: "3",
                            key: index
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsTypeText(items), (item) => {
                                return (_openBlock(), _createBlock(_component_v_text_field, {
                                  key: item.id,
                                  readonly: "",
                                  modelValue: item.value,
                                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                  label: item.label
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                              }), 128)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsTypeTextarea(items), (item) => {
                                return (_openBlock(), _createBlock(_component_v_textarea, {
                                  key: item.id,
                                  readonly: "",
                                  modelValue: item.value,
                                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                  label: item.label
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }))
}