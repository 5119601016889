import { Comment } from '@/interface/Comment';

export default class CommentClass implements Comment {
    content: string = '';
    type: string = '';
    zone: string = '';

    constructor(content: string, type: string, zoneId: string) {
        this.content = content;
        this.type = type;
        this.zone = 'api/zones/' + zoneId;
    }
}
