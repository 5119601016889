
import { defineComponent } from 'vue';
import { mapState, mapStores } from 'pinia';
import { useZonesStore } from '@/store/ZonesStore';
import router from '@/router';
import ZoneApiClass from '@/class/ZoneApiClass';
import { useApiStore } from '@/store/ApiStore';

export default defineComponent({
    name: 'AuthComponent',
    data() {
        return new (class {
            loading: boolean = true;
        })();
    },
    computed: {
        ...mapStores(useZonesStore, useApiStore),
        ...mapState(useZonesStore, ['expertiseData', 'zones']),
        ...mapState(useApiStore, ['expertiseId', 'apiToken', 'isReadonly'])
    },
    methods: {
        setExpertiseId(urlParams: URLSearchParams) {
            if (!urlParams.has('expertise-id')) {
                window.location.href = process.env.VUE_APP_URL_REDIRECT;
            } else {
                // this.zonesStore.setExpertiseId(urlParams.get('expertise-id') ?? '0');
                this.apiStore.setExpertiseId(urlParams.get('expertise-id') ?? '0');
            }
        },
        setApiToken(urlParams: URLSearchParams) {
            if (!urlParams.has('api-token')) {
                window.location.href = process.env.VUE_APP_URL_REDIRECT;
            } else {
                // this.zonesStore.setApiToken(urlParams.get('api-token') ?? '');
                this.apiStore.setApiToken(urlParams.get('api-token') ?? '');
            }
        },
        setIsReadonly() {
            this.apiStore.setIsReadonly(this.$route.name === 'AuthenticateReadonly');
            this.zonesStore.setCanEdit(this.$route.name !== 'AuthenticateReadonly');
        }
    },
    created() {
        sessionStorage.clear();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.setExpertiseId(urlParams);
        this.setApiToken(urlParams);
        this.setIsReadonly();
        this.apiStore
            .getExpertiseData()
            .then((res) => {
                this.zonesStore.setExpertiseData(res.data);
            })
            .then(() => {
                if (this.expertiseData !== null) {
                    if (!this.isReadonly && this.expertiseData.zones.length <= 0) {
                        const zoneApi = new ZoneApiClass(this.expertiseId);
                        this.zonesStore.createNewZone(zoneApi).then((id) => {
                            router.replace({ name: 'Upload', params: { id: parseInt(id) } });
                        });
                    } else if (this.isReadonly && this.expertiseData.zones.length <= 0) {
                        window.location.href = process.env.VUE_APP_URL_REDIRECT;
                    }

                    if (this.expertiseData.zones.length > 0) {
                        this.zonesStore.setZones(this.expertiseData).then(() => {
                            if (this.zones[0].imgMain === null && !this.isReadonly) {
                                // this.zonesStore.setCanEdit(false);
                                router.replace({ name: 'Upload', params: { id: this.zones[0].first_page_id } });
                            } else if (this.zones[0].imgMain !== null) {
                                router.replace({ name: 'NewPage', params: { id: this.zones[0].first_page_id } });
                            } else {
                                window.location.href = process.env.VUE_APP_URL_REDIRECT;
                            }
                        });
                    }
                }
            });
    }
});
