import { ZoneApi } from '@/interface/ZoneApi';
import { Zone } from '@/interface/Zone';

export default class ZoneApiClass implements ZoneApi {
    parentId: number = 0;
    firstPageId: number = 0;
    type: string = 'page';
    points: number[] = [];
    imgBreadcrumb: string | null = null;
    imgMain: string | null = null;
    Expertise: string = '';
    nom: string | null = null;
    stroke: string = '#0000ff';
    timestamp: string = '1';

    constructor(expertiseId: string, zone: Zone | null = null) {
        this.Expertise = '/api/expertises/' + expertiseId;
        if (zone !== null) {
            this.setAttrFromZone(zone);
        }
    }

    setAttrFromZone(zone: Zone) {
        this.parentId = parseInt(zone.parent_id);
        this.firstPageId = parseInt(zone.first_page_id);
        this.type = zone.pageType;
        this.imgBreadcrumb = zone.imgBreadcrumbApi;
        this.imgMain = zone.imgMainApi;
        this.points = zone.points;
        this.Expertise = zone.Expertise;
        this.nom = zone.nomApi;
        this.stroke = zone.stroke;
        this.timestamp = zone.timestampApi;
    }
}
