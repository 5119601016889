import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bandeau_breadcrumb = _resolveComponent("bandeau-breadcrumb")!
  const _component_bandeau_header = _resolveComponent("bandeau-header")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_image = _resolveComponent("v-image")!
  const _component_v_rect = _resolveComponent("v-rect")!
  const _component_v_line = _resolveComponent("v-line")!
  const _component_v_layer = _resolveComponent("v-layer")!
  const _component_v_stage = _resolveComponent("v-stage")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_comment_zone = _resolveComponent("comment-zone")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { style: {"padding":"12px"} }, {
      default: _withCtx(() => [
        (this.zones.length > 0)
          ? (_openBlock(), _createBlock(_component_bandeau_breadcrumb, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_bandeau_header)
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              id: "canvasPlace",
              class: "d-flex justify-center mb-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_stage, {
                  ref: "stage",
                  config: this.stageSize
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_layer, {
                      ref: "layer",
                      onMousedown: _ctx.handleStageMouseDown,
                      onTouchstart: _ctx.handleStageMouseDown,
                      onMousemove: _ctx.handleStageMouseMove
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_image, {
                          onClick: _ctx.openImage,
                          onMouseenter: _ctx.displayZoomIcon,
                          onMouseleave: _ctx.removeZoomIcon,
                          config: {
                                image: this.img.file,
                                x: (this.stageSize.width - this.img.width) / 2,
                                y: (this.stageSize.height - this.img.height) / 2,
                                width: this.img.width,
                                height: this.img.height,
                                pixelRatio: 1
                            }
                        }, null, 8, ["onClick", "onMouseenter", "onMouseleave", "config"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getAnchors, (item) => {
                          return (_openBlock(), _createBlock(_component_v_rect, {
                            key: item.id,
                            config: item,
                            onMouseover: _ctx.handleMouseOverStartPoint,
                            onMouseout: _ctx.handleMouseOutStartPoint,
                            onDragend: _ctx.handleDragEndPoint,
                            onDragmove: _ctx.handleDragMovePoint
                          }, null, 8, ["config", "onMouseover", "onMouseout", "onDragend", "onDragmove"]))
                        }), 128)),
                        (this.imgIsLoad)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getZonesByParentId(_ctx.$route.params.id), (item) => {
                                return (_openBlock(), _createBlock(_component_v_line, {
                                  key: item.id,
                                  config: {
                                    id: item.id,
                                    name: item.name,
                                    scaleX: item.scaleX,
                                    scaleY: item.scaleY,
                                    points: this.zonesStore.getResponsivePoints(this.stageSize, item),
                                    fill: item.fill,
                                    stroke: item.stroke,
                                    strokeWidth: item.strokeWidth,
                                    closed: item.closed,
                                    draggable: item.draggable,
                                    opacity: item.opacity
                                },
                                  onMousemove: _ctx.handleMouseMove,
                                  onMouseleave: _ctx.handleMouseLeave,
                                  onClick: _ctx.nextStep,
                                  onTouchend: _ctx.nextStep
                                }, null, 8, ["config", "onMousemove", "onMouseleave", "onClick", "onTouchend"]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["onMousedown", "onTouchstart", "onMousemove"])
                  ]),
                  _: 1
                }, 8, ["config"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              class: "fill-height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_comment_zone, { "comment-type": 'note-admin' })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              class: "fill-height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_comment_zone, { "comment-type": 'note-client' })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}