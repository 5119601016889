
import { defineComponent } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import { mapState, mapStores } from 'pinia';
import { useApiStore } from '@/store/ApiStore';
import { useZonesStore } from '@/store/ZonesStore';
import CommentClass from '@/class/CommentClass';
import { Zone } from '@/interface/Zone';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default defineComponent({
    inheritAttrs: false,
    name: 'CommentZone',
    components: { QuillEditor },
    props: {
        commentType: {
            required: true,
            type: String,
            default: ''
        }
    },
    data() {
        return new (class {
            toolbar: any = [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons

                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }], // dropdown with defaults from theme
                [{ align: [] }]
            ];
            options: any = {
                readOnly: true,
                theme: 'snow'
            };
            isOkToComment: boolean = false;
            zone: Zone | null = null;
            commentData: string = '';
            comment: { id: number; content: string; type: string } | null = null;
        })();
    },
    watch: {
        $route() {
            this.initCommentData();
        }
    },
    mounted() {
        this.initCommentData();
    },
    computed: {
        ...mapStores(useApiStore, useZonesStore),
        ...mapState(useZonesStore, ['getZoneById']),
        ...mapState(useApiStore, ['isReadonly']),
        enabled() {
            if (this.commentType === 'note-admin' && !this.isReadonly) {
                return true;
            }

            if (this.commentType === 'note-client' && this.isReadonly) {
                return true;
            }

            return false;
        },
        title() {
            return this.commentType === 'note-admin' ? 'Notes Expert' : 'Notes Client';
        }
    },
    methods: {
        initCommentData() {
            this.zone = this.getZoneById(this.$route.params.id.toString());
            if (this.zone !== null) {
                if (this.zone.comments.length > 0) {
                    this.comment = this.zone.comments.sort((a, b) => b.id - a.id).find((comment) => comment.type === this.commentType) ?? null;
                    this.commentData = this.comment !== null ? this.comment.content : '';
                } else {
                    this.commentData = '';
                    this.comment = null;
                }
                //@ts-ignore
                this.$refs.editor.setHTML(this.commentData);
            }

            if (this.enabled && this.isOkToComment) {
                //@ts-ignore
                this.$refs.editor.setHTML(this.commentData);
            }
        },
        addComment() {
            this.isOkToComment = true;
            setTimeout(() => {
                const editor = this.$refs.editor;
                //@ts-ignore
                editor.getQuill().enable(true);
                //@ts-ignore
                editor.getQuill().focus();
            }, 500);
        },
        cancelComment() {
            this.isOkToComment = false;
            const editor = this.$refs.editor;
            //@ts-ignore
            editor.getQuill().enable(false);
            //@ts-ignore
            this.$refs.editor.setHTML('');
        },
        saveComment() {
            this.isOkToComment = false;
            const editor = this.$refs.editor;
            //@ts-ignore
            const comment = new CommentClass(this.$refs.editor.getHTML(), this.commentType, this.zone.id);
            if (this.comment !== null) {
                let commentId = this.comment.id;
                this.apiStore.updateCommentApi(comment, commentId).then(() => {
                    if (this.zone !== null) {
                        this.commentData = comment.content;
                        this.zonesStore.editComment(this.zone, commentId, comment.content, comment.type);
                    }
                });
            } else {
                this.apiStore.addCommentApi(comment).then((res) => {
                    if (this.zone !== null) {
                        this.zonesStore.addComment(this.zone, res.data.id, res.data.content, res.data.type);
                        this.commentData = comment.content;
                    }
                });
            }

            //@ts-ignore
            editor.getQuill().enable(false);
        }
    }
});
