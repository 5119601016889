
import { mapState, mapStores } from 'pinia';
import router from '@/router';
import { useZonesStore } from '@/store/ZonesStore';
import { defineComponent } from 'vue';
import { Zone } from '@/interface/Zone';
import BandeauHeader from '@/components/BandeauHeader.vue';
import Konva from 'konva';
import BandeauBreadcrumb from '@/components/BandeauBreadcrumb.vue';
import { useBreadcrumbStore } from '@/store/BreadcrumbStore';
import { StageSize } from '@/interface/StageSize';
import { Anchor } from '@/interface/Anchor';
import { useApiStore } from '@/store/ApiStore';
import CommentZone from '@/components/CommentZone.vue';

interface Img {
    width: number;
    height: number;
    file: HTMLImageElement | null;
}

export default defineComponent({
    name: 'CreatePage',
    components: { BandeauBreadcrumb, BandeauHeader, CommentZone },
    data() {
        return new (class {
            imgIsLoad: boolean = false;
            page: Zone | null = null;
            stageSize: StageSize = {
                width: 800,
                height: 800
            };
            indexPointX: number = -1;
            indexPointY: number = -1;
            img: Img = {
                width: 500,
                height: 500,
                file: null
            };
            anchorGrabed: Anchor | null = null;
        })();
    },
    beforeRouteUpdate(to) {
        const page = this.getZoneById(to.params.id.toString());
        if (page !== null) {
            this.reload(page);
        }
    },
    computed: {
        ...mapStores(useZonesStore, useBreadcrumbStore, useApiStore),
        ...mapState(useApiStore, ['isReadonly']),
        ...mapState(useZonesStore, ['hasSaveZone', 'zones', 'canEdit', 'selectedShapeId', 'getZonesByParentId', 'getZoneById', 'getAnchors', 'getImgBreadcrumbParent', 'point0'])
    },
    watch: {
        hasSaveZone(zone: Zone | null) {
            if (zone !== null) {
                this.generateImgBreadcrumb(zone).then(() => {
                    router.push('/' + zone.id);
                });
            }
        }
    },
    methods: {
        displayZoomIcon() {
            if (this.page !== null && this.page.pageType === 'zoom') {
                this.getStage().container().style.cursor = 'zoom-in';
            }
        },
        removeZoomIcon() {
            if (this.page !== null && this.page.pageType === 'zoom') {
                this.getStage().container().style.cursor = 'default';
            }
        },
        openImage() {
            if (this.page !== null && this.page.pageType === 'zoom') {
                //@ts-ignore
                window.open(process.env.VUE_APP_URL_BACK_OFFICE + this.page.imgMain, '_blank').focus();
            }
        },
        handleDragEndPoint() {
            const rect = this.getZoneById(this.selectedShapeId);
            if (rect !== null) {
                this.zonesStore.setAnchors(this.stageSize, rect);
            }
            this.anchorGrabed = null;
            this.indexPointX = -1;
            this.indexPointY = -1;
        },
        handleDragMovePoint(e: any) {
            const rect = this.getZoneById(this.selectedShapeId);
            if (rect !== null) {
                if (this.anchorGrabed !== null) {
                    // On empêche la sortie du stage
                    if (e.target.attrs.x + this.anchorGrabed.width / 2 > this.stageSize.width || e.target.attrs.y + this.anchorGrabed.width / 2 > this.stageSize.height) {
                        this.anchorGrabed.draggable = false;
                        return;
                    }

                    if (this.indexPointX >= 0 && this.indexPointY >= 0) {
                        rect.points[this.indexPointX] = e.target.attrs.x / this.stageSize.width;
                        rect.points[this.indexPointY] = e.target.attrs.y / this.stageSize.height;
                    } else {
                        this.anchorGrabed.draggable = false;
                    }
                }
            }
        },
        handleMouseOverStartPoint(e: any) {
            const rect = this.getZoneById(this.selectedShapeId);
            if (rect !== null) {
                if (rect.points.length < 6) {
                    return;
                }
                if (rect.closed || e.target.id() === 'anchor_0') {
                    e.target.scale({ x: 2, y: 2 });
                    if (rect.closed) {
                        this.getStage().container().style.cursor = 'grab';
                        this.zonesStore.enableDraggableAnchors(rect);
                        this.anchorGrabed =
                            this.getAnchors.find((anchor: Anchor) => {
                                return anchor.id === e.target.id();
                            }) ?? null;
                        if (this.anchorGrabed !== null) {
                            this.indexPointX = this.zonesStore.getResponsivePoints(this.stageSize, rect).indexOf(e.target.attrs.x + this.anchorGrabed.width / 2);
                            this.indexPointY = this.zonesStore.getResponsivePoints(this.stageSize, rect).indexOf(e.target.attrs.y + this.anchorGrabed.width / 2);
                        }
                    }
                }
            }
        },
        handleMouseOutStartPoint(e: any) {
            e.target.scale({ x: 1, y: 1 });
        },
        addPoint() {
            const rect = this.getZoneById(this.selectedShapeId);
            const stage = this.getStage();
            if (rect && stage.getPointerPosition() !== null && !rect.closed) {
                const minPosPointX0 = this.zonesStore.point0[0] * 0.95;
                const maxPosPointX0 = this.zonesStore.point0[0] * 1.05;
                const minPosPointY0 = this.zonesStore.point0[1] * 0.95;
                const maxPosPointY0 = this.zonesStore.point0[1] * 1.05;
                const userPointerPositionX = stage.getPointerPosition()?.x ?? 0;
                const userPointerPositionY = stage.getPointerPosition()?.y ?? 0;

                if (userPointerPositionX >= minPosPointX0 && userPointerPositionX <= maxPosPointX0 && userPointerPositionY >= minPosPointY0 && userPointerPositionY <= maxPosPointY0) {
                    //On retire le x et y du dernier point temporaire
                    rect.points.pop();
                    rect.points.pop();
                    rect.closed = true;
                    this.zonesStore.enableDraggableAnchors(rect);
                    this.zonesStore.resetPoint0();
                } else {
                    this.zonesStore.addPoint(
                        {
                            x: userPointerPositionX,
                            y: userPointerPositionY
                        },
                        this.stageSize,
                        rect
                    );
                    this.zonesStore.setPoint0([userPointerPositionX, userPointerPositionY]);
                    this.zonesStore.setAnchors(this.stageSize, rect);
                }
            }
        },
        handleStageMouseDown(e: any) {
            // clicked on stage - clear selection
            if (e.target === e.target.getStage()) {
                this.zonesStore.setSelectedShapeId(null);
                return;
            }

            if (this.canEdit && this.selectedShapeId !== null) {
                this.addPoint();
            } else if (this.canEdit && this.selectedShapeId === null) {
                // find clicked rect by its id
                const rect = this.getZoneById(e.target.id());
                if (rect) {
                    this.zonesStore.setSelectedShapeId(rect.id);
                    this.zonesStore.setAnchors(this.stageSize, rect);
                }
            } else {
                return;
            }
        },
        handleStageMouseMove() {
            if (this.canEdit && this.selectedShapeId !== null) {
                const rect = this.getZoneById(this.selectedShapeId);
                const stage = this.getStage();
                if (rect && stage.getPointerPosition() !== null && !rect.closed) {
                    this.zonesStore.addCurrentMousePoint(
                        {
                            x: stage.getPointerPosition()?.x ?? 0,
                            y: stage.getPointerPosition()?.y ?? 0
                        },
                        this.stageSize,
                        rect
                    );
                }
            }
        },
        handleMouseMove() {
            if (!this.canEdit) {
                this.getStage().container().style.cursor = 'pointer';
            }
        },
        handleMouseLeave() {
            this.getStage().container().style.cursor = 'default';
        },
        scalePreserveAspectRatio(imgW: number, imgH: number, maxW: number, maxH: number) {
            return Math.min(maxW / imgW, maxH / imgH);
        },
        getStage() {
            // return e.target.getStage() as Konva.Stage;
            //@ts-ignore
            return this.$refs.stage.getStage() as Konva.Stage;
        },
        nextStep(e: any) {
            let zone = this.getZoneById(e.target.attrs.id);

            if (zone !== null && !this.canEdit) {
                this.zonesStore.setSelectedShapeId(null);
                // this.generateImgBreadcrumb(e, zone)
                this.generateImgBreadcrumb(zone).then((res) => {
                    if (res && zone !== null) {
                        if (zone.imgMain === null && !this.isReadonly) {
                            // this.zonesStore.setCanEdit(false);
                            router.push('/' + zone.id);
                        } else {
                            // this.zonesStore.setCanEdit(zone.pageType === 'page');
                            if (zone.imgMainApi !== null) {
                                router.push('/newPage/' + zone.id);
                            }
                            //@TODO Voir pour mettre une alert ici comme quoi il n'y a pad d'image ou zone encore dde créé
                        }
                    }
                });
            }
        },
        generateImgBreadcrumb(zone: Zone) {
            const stage = this.getStage();
            return new Promise((resolve) => {
                if (zone.parent_id === zone.first_page_id) {
                    zone.fill = '#D0D0D0';
                    zone.opacity = 0.7;
                    setTimeout(() => {
                        let stage64 = stage.toDataURL({
                            pixelRatio: 0.5
                        });
                        fetch(stage64)
                            .then((res) => res.blob())
                            .then((blob: Blob) => {
                                this.apiStore.saveImageBreadcrumb(blob, zone).then(() => {
                                    zone.fill = null;
                                    zone.opacity = null;
                                    resolve(true);
                                });
                            });
                    }, 1);
                } else {
                    zone.imgBreadcrumb = this.getImgBreadcrumbParent(zone.parent_id);
                    resolve(true);
                }
            });
        },
        reload(page: Zone) {
            this.zonesStore.setHaveSaveZone(null);
            this.imgIsLoad = false;
            this.breadcrumbStore.updateItems(page.id);
            this.breadcrumbStore.updateDisabledLink(parseInt(page.id));
            this.page = page;
            const canvas = document.getElementById('canvasPlace');
            this.stageSize.width = window.innerWidth;
            if (canvas !== null) {
                this.stageSize.width = canvas.offsetWidth;
            }
            this.stageSize.height = window.innerHeight - window.innerHeight * 0.2;
            if (page.imgMain !== null) {
                const image = new window.Image();
                image.src = process.env.VUE_APP_URL_BACK_OFFICE + page.imgMain;
                image.crossOrigin = 'Anonymous';
                image.onload = () => {
                    let sizer = this.scalePreserveAspectRatio(image.width, image.height, this.stageSize.width, this.stageSize.height);
                    this.img.width = image.width * sizer;
                    this.img.height = image.height * sizer;
                    this.stageSize.width = this.img.width;
                    this.stageSize.height = this.img.height;
                    this.img.file = image;
                    this.imgIsLoad = true;
                };
            } else {
                this.img.file = null;
            }
        }
    },
    mounted() {
        this.page = this.getZoneById(this.$route.params.id as string);
        if (this.page !== null) {
            this.reload(this.page);
        }
    }
});
