import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-center align-center h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bandeau_breadcrumb = _resolveComponent("bandeau-breadcrumb")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_VueCropper = _resolveComponent("VueCropper")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { style: {"padding":"12px"} }, {
      default: _withCtx(() => [
        (this.zonesStore.zones.length > 0)
          ? (_openBlock(), _createBlock(_component_bandeau_breadcrumb, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("input", {
            ref: "FileInput",
            type: "file",
            style: {"display":"none"},
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
            accept: "image/*"
          }, null, 544),
          _createVNode(_component_v_btn, {
            color: "blue-grey",
            "prepend-icon": "fa-solid fa-cloud-arrow-up",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.FileInput.click()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Upload")
            ]),
            _: 1
          }),
          _createVNode(_component_v_dialog, {
            modelValue: this.dialog,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.dialog) = $event)),
            width: this.imgStyle.width,
            persistent: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_item, null, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_VueCropper, {
                        ref: "cropper",
                        src: this.selectedFile,
                        alt: "Source Image",
                        "img-style": this.imgStyle,
                        guides: true,
                        "view-mode": 1,
                        background: false,
                        modal: false,
                        center: true,
                        highlight: true,
                        rotatable: true,
                        autoCropArea: 0.99
                      }, null, 8, ["src", "img-style", "autoCropArea"]), [
                        [_vShow, this.selectedFile]
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_slider, {
                        modelValue: this.rotateImage,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.rotateImage) = $event)),
                        max: "90",
                        min: "-90",
                        step: "1"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "primary",
                        onClick: _ctx.saveImage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Recadrer")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        text: "",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (this.dialog = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Annuler")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "width"])
        ])
      ]),
      _: 1
    })
  ], 64))
}