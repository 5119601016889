import { Anchor } from '@/interface/Anchor';

export default class AnchorClass implements Anchor {
    id: string;
    width: number = 15;
    height: number = 15;
    x: number;
    y: number;
    fill: string = 'white';
    stroke: string = 'black';
    strokeWidth: number = 1;
    draggable: boolean = false;

    constructor(id: string, x: number, y: number) {
        this.id = id;
        this.x = x - this.width / 2;
        this.y = y - this.width / 2;
    }
}
