<template>
    <v-container fluid>
        <section class="page_404">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-10 col-sm-offset-1 text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="text-center">404</h1>
                            </div>

                            <div class="contant_box_404">
                                <h3 class="h2">Look like you're lost</h3>

                                <p>the page you are looking for not available!</p>
                                <router-link :to="href">
                                    <a class="link_404">Go to Home</a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </v-container>
</template>

<script>
import { mapState } from 'pinia';
import { useZonesStore } from '@/store/ZonesStore';

export default {
    name: 'NotFound',
    computed: {
        ...mapState(useZonesStore, ['zones']),
        href() {
            if (this.zones.length > 0) {
                let href = '/' + this.zones[0].first_page_id;
                if (this.zones[0].imgMain !== null) {
                    href = '/newPage/' + this.zones[0].first_page_id;
                }
                return href;
            }
            return process.env.VUE_APP_URL_REDIRECT;
        }
    }
};
</script>

<style scoped>
.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    border-radius: 10px;
}

.contant_box_404 {
    margin-top: -50px;
}
</style>
