import { _StoreWithState, defineStore } from 'pinia';
import { BreadcrumbItem } from '@/interface/BreadcrumbItem';
import { Zone } from '@/interface/Zone';
import { useZonesStore } from '@/store/ZonesStore';
import BreadcrumbClass from '@/class/BreadcrumbClass';

interface BreadcrumbState {
    items: BreadcrumbItem[];
}

export const useBreadcrumbStore = defineStore({
    id: 'breadcrumb',
    state: (): BreadcrumbState => {
        return {
            items: []
        };
    },
    persist: {
        storage: sessionStorage
    },
    actions: {
        addItem(item: BreadcrumbItem) {
            if (!this.items.some((e) => e.id === item.id)) {
                this.items.push(item);
            }
        },
        updateItems(id: string, clear: boolean = true) {
            const zoneStore = useZonesStore();
            if (clear) {
                this.items = [];
            }
            const zoneBase = zoneStore.$state.zones.find((zone: Zone) => zone.id === id) ?? null;
            if (zoneBase !== null) {
                const zoneParent = zoneStore.$state.zones.find((zone: Zone) => zoneBase.parent_id === zone.id) ?? null;
                if (zoneParent !== null) {
                    // const breadcrumbItemExpertises = new BreadcrumbClass(0, 'Liste des Expertises', null, process.env.VUE_APP_URL_REDIRECT);
                    // this.addItem(breadcrumbItemExpertises);
                    const idBreadcrumb = parseInt(id);
                    const titleBreadcrumb = zoneBase.nomApi !== null ? zoneBase.nomApi : zoneStore.expertiseData !== null ? zoneStore.expertiseData.titre : 'Page 1';
                    const hrefBreadcrumb = '/newPage/' + id;
                    const breadcrumbItem = new BreadcrumbClass(idBreadcrumb, titleBreadcrumb, hrefBreadcrumb);
                    this.addItem(breadcrumbItem);
                    if (zoneBase.id !== zoneParent.id) {
                        this.updateItems(zoneParent.id, false);
                    }
                }
            }

            this.items = this.items.sort(({ id: a }, { id: b }) => a - b);
        },
        updateDisabledLink(id: number) {
            this.items.map((item) => {
                item.disabled = item.id === id;
            });
        }
    }
});
