
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useZonesStore } from '@/store/ZonesStore';
import { useApiStore } from '@/store/ApiStore';
import moment from 'moment';

export default defineComponent({
    name: 'InformationsHeader',
    computed: {
        ...mapState(useZonesStore, ['canEdit', 'selectedShapeId', 'zones', 'expertiseData']),
        ...mapState(useApiStore, ['expertiseId', 'apiToken', 'isReadonly'])
    },
    methods: {
        itemsTypeText(items: any) {
            return items.filter((item: any) => item.inputType === 'text');
        },
        itemsTypeTextarea(items: any) {
            return items.filter((item: any) => item.inputType === 'textarea');
        }
    },
    mounted() {
        this.dataItems.forEach((data) => {
            data.forEach((htmlData) => {
                if (['heureDebutVols', 'heureFinVols'].includes(htmlData.apiKey)) {
                    //@ts-ignore
                    htmlData.value = moment(this.expertiseData[htmlData.apiKey]).utc(false).format('DD/MM/YYYY HH:mm:ss');
                } else {
                    //@ts-ignore
                    htmlData.value = this.expertiseData[htmlData.apiKey];
                }
            });
        });
    },
    data() {
        return {
            info: false,
            dataItems: [
                [
                    {
                        id: 0,
                        apiKey: 'titre',
                        label: 'Titre',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 1,
                        apiKey: 'adresse',
                        label: 'Adresse',
                        inputType: 'textarea',
                        value: ''
                    },
                    {
                        id: 2,
                        apiKey: 'codePostal',
                        label: 'CP',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 3,
                        apiKey: 'ville',
                        label: 'Ville',
                        inputType: 'text',
                        value: ''
                    }
                ],
                [
                    {
                        id: 0,
                        apiKey: 'LatGPS',
                        label: 'Lat GPS',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 1,
                        apiKey: 'LonGPS',
                        label: 'Long GPS',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 2,
                        apiKey: 'heureDebutVols',
                        label: 'Heure Début des vols',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 3,
                        apiKey: 'heureFinVols',
                        label: 'Heure Fin des vols',
                        value: ''
                    },
                    {
                        id: 4,
                        apiKey: 'temperatureExt',
                        label: 'Température extérieur °C',
                        inputType: 'text',
                        value: ''
                    }
                ],
                [
                    {
                        id: 0,
                        apiKey: 'temperatureInt',
                        label: 'Température intérieur °C',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 1,
                        apiKey: 'hygrometrie',
                        label: '% HR',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 2,
                        apiKey: 'meteo',
                        label: 'Météo',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 3,
                        apiKey: 'materielUtilise',
                        label: 'Matériel utilisé',
                        inputType: 'textarea',
                        value: ''
                    },
                    {
                        id: 4,
                        apiKey: 'definitionCamera',
                        label: 'Définition caméra',
                        inputType: 'text',
                        value: ''
                    }
                ],
                [
                    {
                        id: 0,
                        apiKey: 'infosLibres',
                        label: 'Infos libre',
                        inputType: 'textarea',
                        value: ''
                    },
                    {
                        id: 1,
                        apiKey: 'telepiloteNom',
                        label: 'Télépilote Nom',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 2,
                        apiKey: 'telepilotePrenom',
                        label: 'Télépilote Prénom',
                        inputType: 'text',
                        value: ''
                    },
                    {
                        id: 3,
                        apiKey: 'telepiloteSpecificite',
                        label: 'Télépilote spécificité',
                        inputType: 'text',
                        value: ''
                    }
                ]
            ]
        };
    }
});
